import React from "react";
import Button from "../../atoms/Button/Button";
import styled from "styled-components";
import phoneSrc from "../../../assets/Images/Icons/phone.svg";
import { SubHeadLineOne } from "../../atoms/SubHeadLineOne/SubHeadLineOne";
import { HeadLine } from "../../atoms/HeadLine/HeadLine";

const JumboColumnBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  min-height: 500px;
  @media (max-width: 1325px) {
    justify-content: space-around;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5);
    width: 50vw;
    border-radius: 20px;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 70vw;
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.small}) {
    width: 90vw;
  }
`;

const PhoneSvg = styled.img`
  max-width: 15px;
  margin-right: 5px;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1325px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
`;

const JumboColumn = () => {
  return (
    <JumboColumnBox>
      <div data-aos="fade-right" data-aos-offset="-100">
        <HeadLine>Nowe Osiedle</HeadLine>
        <br />
        <HeadLine>w Mławie</HeadLine>
      </div>
      <SubHeadLineOne data-aos="fade-right" data-aos-offset="-100">
        W zabudowie <br /> bliźniaczej i szeregowej
      </SubHeadLineOne>
      <ButtonBox data-aos="fade-up" data-aos-offset="-100">
        <Button dest="/#contact">
          <PhoneSvg src={phoneSrc} alt="phone icon" />
          Skontaktuj się
        </Button>
        <Button secondary dest="/#gallery">
          Zobacz więcej
        </Button>
      </ButtonBox>
    </JumboColumnBox>
  );
};

export default JumboColumn;
