import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import SectionHeader from "../../molecules/SectionHeader/SectionHeader";
import { SmallText } from "../../atoms/SmallText/SmallText";
import Button from "../../atoms/Button/Button";
import phoneSrc from "../../../assets/Images/Icons/phone.svg";
import HumanSVG from "../../../assets/Images/human.svg";

const AboutUsSection = styled.section`
  margin-top: 50px;
  margin-bottom: 100px;
`;

const AboutUsContainer = styled.div`
  background-color: #f6f6f8;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
    z-index: 1;
  }
`;

const AboutUsWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column-reverse;
    width: 90%;
  }

  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    width: 90%;
    padding-top: 100px;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  width: 35%;
  display: flex;
  align-items: flex-end;

  img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    width: 20%;
  }

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 50%;
    align-self: center;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    width: 70%;
  }

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 50px;
  }
`;

const PhoneSvg = styled.img`
  max-width: 15px;
  margin-right: 5px;
`;

const StyledSmallText = styled(SmallText)`
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledAboutText = styled.div`
  & > * {
    font-size: ${({ theme }) => theme.fontOptions.smallText.size};
    line-height: ${({ theme }) => theme.fontOptions.smallText.lineHeight};
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontOptions.headLine.fontWeight};
  }
`;

const BoldText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontOptions.headLine.fontWeight};
`;

const AboutUs = () => {
  const data = useStaticQuery(query);
  return (
    <AboutUsSection>
      <SectionHeader>O nas</SectionHeader>
      <AboutUsContainer>
        <AboutUsWrapper>
          <ImageWrapper>
            <img src={HumanSVG} alt="O nas" />
          </ImageWrapper>
          <TextWrapper>
            {/* <StyledSmallText>
              Jesteśmy <BoldText>Innovo Property s.c.</BoldText>
              <br />
              Oferujemy przestronne, nowoczesne i wysokiej jakości mieszkania o designie spełniającym potrzeby
              współczesnych konsumentów.
              <br />
              Planujemy budowę nieruchomości w <BoldText>najlepszych lokalizacjach</BoldText> w całym kraju, dzięki
              czemu ludzie będą mieli najlepsze warunki do życia.
              <br />
              Budujemy dom, który odpowiada Twoim <BoldText>potrzebom i budżetowi.</BoldText>
              <br />
              <BoldText>Wiedz,</BoldText> że dokonujesz właściwego wyboru kupując swój dom od firmy Innovo.
            </StyledSmallText> */}
            <StyledAboutText
              dangerouslySetInnerHTML={{
                __html: data.datoCmsAbout.aboutText,
              }}
            />
            <Button dest="/#contact">
              <PhoneSvg src={phoneSrc} alt="phone icon" />
              Skontaktuj się
            </Button>
          </TextWrapper>
        </AboutUsWrapper>
      </AboutUsContainer>
    </AboutUsSection>
  );
};

export const query = graphql`
  query AboutUsQuery {
    datoCmsAbout {
      aboutText
    }
  }
`;

export default AboutUs;
