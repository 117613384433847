import React from "react";
import styled from "styled-components";
import Button from "../../atoms/Button/Button";
import phoneSrc from "../../../assets/Images/Icons/phone.svg";
import { HeadLine } from "../../atoms/HeadLine/HeadLine";
import { NormalText } from "../../atoms/NormalText/NormalText";
import { NormalTextBold } from "../../atoms/NormalTextBold/NormalTextBold";
import { SmallText } from "../../atoms/SmallText/SmallText";
import { SmallTextBold } from "../../atoms/SmallTextBold/SmallTextBold";

const PhoneSvg = styled.img`
  max-width: 15px;
  margin-right: 5px;
`;

const AboutTextColumnBox = styled.div`
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 90vw;
    margin: auto;
    margin-bottom: 20px;
  }
`;

const AboutTextColumn = () => {
  return (
    <AboutTextColumnBox data-aos="fade-left">
      <div>
        <HeadLine>O osiedlu</HeadLine>
      </div>
      <NormalText>
        <NormalTextBold>MELODY PARK Mława</NormalTextBold> zlokalizowany przy ul. Saksofonowej w Mławie to
        <NormalTextBold> niespotykana </NormalTextBold>
        dotychczas w naszym mieście inwestycja budowy zespołu domów w zabudowie bliźniaczej i szeregowej.
      </NormalText>
      <SmallText>
        Nazwa osiedla bardzo <SmallTextBold>przyjazna i ciepła, </SmallTextBold>kojarząca się ze światem ulubionych
        dźwięków oraz <SmallTextBold>zielonej natury, </SmallTextBold>informuje, że osiedle dedykowane jest przede
        wszystkim rodzinom i osobom poszukującym niewielkiego domu w <SmallTextBold>doskonałej </SmallTextBold>{" "}
        lokalizacji z dala od miejskiego zgiełku oraz jednoczesnej bliskości przyrody. Przemyślana funkcja i rozkład
        pomieszczeń oraz całkowita powierzchnia domów wynosi od 119,1 do 135,55 m2.
      </SmallText>
      <Button dest="/#contact">
        <PhoneSvg src={phoneSrc} alt="phone icon" />
        Skontaktuj się
      </Button>
    </AboutTextColumnBox>
  );
};

export default AboutTextColumn;
