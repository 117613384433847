import styled from "styled-components";

export const SubHeadLineOne = styled.p`
  font-size: ${({ theme }) => theme.fontOptions.subHeadLineOne.size};
  line-height: ${({ theme }) => theme.fontOptions.subHeadLineOne.lineHeight};
  font-weight: ${({ theme }) => theme.fontOptions.subHeadLineOne.fontWeight};
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    font-size: ${({ theme }) => theme.fontOptions.subHeadLineTwo.size};
    line-height: ${({ theme }) => theme.fontOptions.subHeadLineTwo.lineHeight};
  }
`;
