import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import SectionHeader from "../../molecules/SectionHeader/SectionHeader";
import LocationBox from "../../atoms/LocationBox/LocationBox";
import { graphql, useStaticQuery } from "gatsby";
import ChildrenSVG from "../../../assets/Images/Icons/Children.svg";
import PlaceSVG from "../../../assets/Images/Icons/place.svg";
import PartySVG from "../../../assets/Images/Icons/Party.svg";

const LocationSection = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-left: auto;
  width: 90vw;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column-reverse;
    margin-left: 0;
    width: 100%;
  }
`;

const LocationBoxesContainer = styled.div`
  width: 35%;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 90vw;
    margin: auto;
  }
`;

const LocationMap = styled.div`
  width: 55%;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 90vw;
    margin: auto;
    margin-bottom: 20px;
  }

  .gatsby-image-wrapper img,
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

const Location = () => {
  const data = useStaticQuery(query);
  return (
    <LocationSection id="location">
      <SectionHeader>Lokalizacja</SectionHeader>
      <LocationContainer>
        <LocationBoxesContainer>
          <LocationBox
            image={PlaceSVG}
            imageAlt="Alt"
            text="Świetna lokalizacja - niedaleko dzielnica Przemysłowa oraz Dworzec PKP."
          />
          <LocationBox image={ChildrenSVG} imageAlt="Alt" text="Zielona okolica - osiedle otaczają lasy oraz pola." />
          <LocationBox
            image={PartySVG}
            imageAlt="Alt"
            text="Blisko Zalew Ruda - tylko 5 minut samochodem do Twojego ulubionego miejsca odpoczynku."
          />
        </LocationBoxesContainer>
        <LocationMap>
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Lokalizacja Melody Park" />
        </LocationMap>
      </LocationContainer>
    </LocationSection>
  );
};

const query = graphql`
  query LocationMap {
    file(relativePath: { eq: "infographic_map.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
  }
`;

export default Location;
