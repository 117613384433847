import React, { useEffect } from "react";
import AOS from "aos";
import About from "../components/organisms/About/About";
import Contact from "../components/organisms/Contact/Contact";
import Gallery from "../components/organisms/Gallery/Gallery";
import Jumbo from "../components/organisms/Jumbo/Jumbo";
import Standard from "../components/organisms/Standard/Standard";
import MainLayout from "../layouts/MainLayout/MainLayout";
import "aos/dist/aos.css";
import InteractiveVisualisation from "../components/molecules/InteractiveVisualisation/InteractiveVisualisation";
import Location from "../components/organisms/Location/Location";
import AboutUs from "../components/organisms/AboutUs/AboutUs";

export default function Home() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 600,
    });
  }, []);
  return (
    <MainLayout>
      <Jumbo />
      <About />
      <Gallery />
      <InteractiveVisualisation />
      <Location />
      <Standard />
      <AboutUs />
      <Contact />
    </MainLayout>
  );
}
