import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageBox = styled.div`
  width: 90vw;
  min-width: 1300px;
  margin-right: -35%;
  @media (max-width: 1325px) {
    width: 100vw;
    z-index: -1;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    min-width: 930px;
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

const JumboImg = () => {
  const data = useStaticQuery(query);
  return (
    <ImageBox>
      <Image imgStyle={{ objectFit: "contain" }} image={data.file.childImageSharp.gatsbyImageData} alt="Houses image" />
    </ImageBox>
  );
};

const query = graphql`
  query JumboImage {
    file(relativePath: { eq: "Image-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
  }
`;

export default JumboImg;
