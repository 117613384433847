import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import PropTypes from "prop-types";
import SliderButtons from "../../molecules/SliderButtons/SliderButtons";

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const ModalContainer = styled(Modal)`
  width: 55%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  z-index: 3;
`;

const SlideBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
`;

const ModalSlider = ({ isOpen, changeOpen, index, images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  useEffect(() => {
    window.innerWidth <= 575 ? changeOpen(false) : "";
  });

  useEffect(() => {
    setImageIndex(index);
  });

  useEffect(() => {
    if (index !== imageIndex) {
      if (imageIndex > index) {
        setImageIndex(imageIndex);
      } else if (imageIndex < index) {
        setImageIndex(imageIndex);
      }
    }
  }, [imageIndex]);

  const closeModal = () => {
    changeOpen(false);
  };

  Modal.setAppElement("body");

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={closeModal} contentLabel="Example Modal">
      <SlideBox>
        {images.map((image, index) =>
          imageIndex === index ? (
            <StyledImg image={images[imageIndex].gatsbyImageData} key={imageIndex} alt="Modal image" />
          ) : (
            ""
          )
        )}
      </SlideBox>
      <SliderButtons
        leftClick={() => (imageIndex <= 0 ? setImageIndex(images.length - 1) : setImageIndex(imageIndex - 1))}
        rightClick={() => (imageIndex === images.length - 1 ? setImageIndex(0) : setImageIndex(imageIndex + 1))}
      />
    </ModalContainer>
  );
};

ModalSlider.propTypes = {
  isOpen: PropTypes.bool,
  changeOpen: PropTypes.func,
  index: PropTypes.number,
  images: PropTypes.array,
};

export default ModalSlider;
