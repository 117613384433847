import React from "react";
import styled from "styled-components";
import CarImgSrc from "../../../assets/Images/Icons/podjazd.svg";
import BrickImgSrc from "../../../assets/Images/Icons/fundamenty.svg";
import CableImgSrc from "../../../assets/Images/Icons/instalacje.svg";
import HeatingImgSrc from "../../../assets/Images/Icons/ogrzewanie.svg";
import FacadesImgSrc from "../../../assets/Images/Icons/elewacja.svg";
import SafetyImgSrc from "../../../assets/Images/Icons/bezpieczenstwo.svg";
import StandardIcon from "../../atoms/StandardIcon/StandardIcon";

const StandardIconRowBox = styled.div`
  width: 80vw;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 70px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StandardIconRow = () => {
  return (
    <StandardIconRowBox>
      <StandardIcon title="Podjazd" imageSrc={CarImgSrc}>
        Podjazdy do budynków i tarasy wykonane z osobnej kostki brukowej.
      </StandardIcon>
      <StandardIcon title="Fundamenty" imageSrc={BrickImgSrc}>
        Budowa domu na fundamentach przygotowanych przez Inwestora.
      </StandardIcon>
      <StandardIcon title="Instalacje" imageSrc={CableImgSrc}>
        Wodno-kanalizacyjne, elektryczne, radiowo-telewizyjne, węzeł centralnego ogrzewania.
      </StandardIcon>
      <StandardIcon title="Ogrzewanie" imageSrc={HeatingImgSrc}>
        Domy są bardzo ciepłe, realizowane z betonu komórkowego H+H 24 cm, dodatkowo 20 cm ocieplenie, blacho-dachówka.
      </StandardIcon>
      <StandardIcon title="Elewacja" imageSrc={FacadesImgSrc}>
        Elewacja tynk strukturalny z elementami imitującymi drewno, balustrady, i balkony-szkło hartowane i aluminium.
      </StandardIcon>
      <StandardIcon title="Bezpieczeństwo" imageSrc={SafetyImgSrc}>
        Teren osiedla ogrodzony z bramą główną i instalacją domofonową.
      </StandardIcon>
    </StandardIconRowBox>
  );
};

export default StandardIconRow;
