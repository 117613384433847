import React from "react";
import styled from "styled-components";
import { SmallText } from "../SmallText/SmallText";

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;
const BoxImg = styled.img`
  width: 250px;

  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    width: 150px;
  }
`;

const BoxText = styled(SmallText)`
  text-align: center;
`;

const LocationBox = ({ image, imageAlt, text }) => {
  return (
    <BoxWrapper>
      <BoxImg src={image} alt={imageAlt} />
      <BoxText>{text}</BoxText>
    </BoxWrapper>
  );
};

export default LocationBox;
