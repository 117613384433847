import React from "react";
import styled from "styled-components";
import SectionHeader from "../../molecules/SectionHeader/SectionHeader";
import Slider from "../../molecules/Slider/Slider";

const GalleryBox = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 150px;
`;

const Gallery = () => {
  return (
    <GalleryBox id="gallery">
      <SectionHeader>Galeria zdjęć</SectionHeader>
      <Slider />
    </GalleryBox>
  );
};

export default Gallery;
