import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { SmallText } from "../SmallText/SmallText";
import { NormalTextBold } from "../../atoms/NormalTextBold/NormalTextBold";

const StandardIconBox = styled.div`
  width: 25vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    width: 35vw;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 50vw;
    margin: 20px 0;
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.small}) {
    width: 80vw;
  }
`;
const IconBox = styled.div`
  img {
    max-width: 45px;
  }
`;

const StandardIcon = ({ imageSrc, children, title }) => {
  return (
    <StandardIconBox>
      <IconBox data-aos="flip-right" data-aos-duration="700">
        <img src={imageSrc} />
      </IconBox>
      <div data-aos="fade-up">
        <NormalTextBold style={{ color: "black" }}>{title}</NormalTextBold>
        <SmallText>{children}</SmallText>
      </div>
    </StandardIconBox>
  );
};

StandardIcon.propTypes = {
  children: PropTypes.string,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
};

export default StandardIcon;
