import React from "react";
import styled from "styled-components";
import JumboImg from "../../atoms/JumboImg/JumboImg";
import JumboColumn from "../../molecules/JumboColumn/JumboColumn";

const JumboBox = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
`;

const Jumbo = () => {
  return (
    <JumboBox>
      <JumboColumn />
      <JumboImg />
    </JumboBox>
  );
};

export default Jumbo;
